import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import LoginButton from '../auth/LoginButton';

const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: rgb(255, 0, 0) solid 2px;
  width: auto;
  align-self: center;
  min-height: 200px;
  padding: 48px 32px 32px 40px;
  background-color: #ffffffc9;
`;

const Picture = styled.img`
  height: 128px;
  width: 128px;
  align-self: center;
`;
const Main = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1000%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='%230e2a47'%3e%3c/rect%3e%3cpath d='M318.62 639.16C438.71 635.46 538.37 453.66 761.88 453.42 985.39 453.18 983.51 523.42 1205.13 523.42 1426.76 523.42 1536.2 453.64 1648.39 453.42' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M565.6 659.73C674.48 645.23 650.26 394.34 915.43 393.93 1180.61 393.52 1437.96 505.58 1615.1 505.93' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M95.45 666.09C306.41 648.04 449.92 217.18 813.19 216.49 1176.46 215.8 1342.98 437.91 1530.93 440.49' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M620.02 645.69C761.62 621.4 829.97 274.43 1056.72 272.13 1283.46 269.83 1379.59 399.6 1493.41 400.93' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M887.01 565.87C1034.06 524.21 1084.65 113.89 1296.61 112.02 1508.57 110.15 1588.41 339.05 1706.21 347.22' stroke='rgba(51%2c121%2c194%2c0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1000'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  @media (max-width: 767px) {
    background-size: cover;
    background-position: center;
  }
`;

const Profile = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  if (!isLoading && isAuthenticated)
    return (
      !isLoading &&
      isAuthenticated && (
        <Main>
          <Card>
            <Picture src={user.picture} alt={user.nickname} />
            <h2>{user.nickname}</h2>
            <p>{user.email}</p>
            {/*   {JSON.stringify(user, null, 2)} */}
          </Card>
        </Main>
      )
    );
  else
    return (
      <Main>
        <Card>
          <h2>LOGIN</h2>
          <LoginButton />
        </Card>
      </Main>
    );
};
export default Profile;
